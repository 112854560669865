<template>
  <div v-bind:style="styles" id="Pgwrp" ref="Pgwrp">
    <div id="page-content" v-if="!viLoader">
      <!-- <div class="dispflex" style="align-items: baseline">
        <h1 class="hdrtitle ml-2" style="margin-top: 0px;">Fees Management</h1>
                  <button
                    type="button"
                    id="prevBtn"
                    class="backbtn"
                   @click="previousRoute"
                  >
                   <i class="fa fa-arrow-left"></i>
                  </button>
      </div> -->
      <div class="webNew-dispflex"   >
       
       <!-- <div class="backbox">
         <div class="back-arrow" @click="previousRoute">
           <i class="fa fa-arrow-left" style="padding-top: 11px;padding-left: 11px;"></i>
         </div>

       </div> -->
       <h4 class="hdrtitle backHeading" style="margin-top: 0px;">
        Fees Management
       </h4>
      </div>
    
      <div class="row"> 
        <div class="col-md-12 col-lg-12 col-sm-12">
          <div class="widjet m-2">
            <div class="widjethdr dispflex">
              <div style="width: auto">
                <span class="iconsect blubg" style="padding-left: 12px"
                  ><i class="fas fa-receipt"></i
                ></span>
                <h1>View Fee Plans Invoice</h1>
              </div>
             
               <div style="margin-right: 41%;
    width: 20%;
    margin-top: 8px;">
                <span class="has-float-label">
                   <select
                      @change="getSelectAcademicYear"
											class="form-control form-input"
											id="deptlistid"
											v-model="selectAcademicYear"
											>
											<option :value=null>--Select--</option>
											<option v-for="(item,index) in academicYearList" :key="index" :value="item._id">
												{{ item.from}} - {{ item.to }}
											</option>
											</select>
											<label for="acyearid">Academic Year <span class="required">*</span></label>
                </span>
              </div>
              <!-- <div class="mt-1">
                
                <button class="btn gry_bg tooltipt mpopup twoicn" v-b-modal.crtfeeplan>
                  <i class="fas fa-receipt user1"></i>
                        <p class="user2"><i class="fas fa-plus"></i></p>
                        <span class="tooltiptext">Create Fee Plan</span>
                </button>
              </div> -->
            </div>
            <div class="widjetcontent">
            
                <div class="crtfeeplancont" style="padding: 0 13px">
                  <div
                    class="widjethdr dispflex"
                    style="align-items: baseline; padding: 10px 0"
                  >
                    <!-- <div style="width: auto">
                      <h1 style="margin: 0">Invoice</h1>
                    </div> -->
                    <!-- <div><input type="text" v-model="searchWords" placeholder="Search Fee plans"/></div>
                    <div class="mr-1">
                      
                      <button
                        class="btn gry_bg tooltipt mpopup twoicn"
                        v-b-modal.crtfeeplan
                      >
                        <i class="fas fa-file-invoice user1"></i>
                          <p class="user2"><i class="fas fa-plus"></i></p>
                        <span class="tooltiptext">Create Fee Plan</span>
                      </button>
                    </div> -->
                  </div>
                  <div class="restable">
                    <div class="resrow resheader">
                      <div class="cell">Invoice Name</div>
                      <div class="cell">Invoice Type</div>
                      <div class="cell">Fee Plan Start Date</div>
                      <div class="cell">Fee Plan End Date</div>
                      <div class="cell">Created On</div>
                      <div class="cell">Total Students(fee assigned)</div>
                      <div class="cell">Not Paid</div>
                      <div class="cell">Partially Paid</div>
                      <div class="cell">Fully Paid</div>
                      <div class="cell">Cancelled</div>
                      <!-- <div class="cell">Action</div> -->
                    </div>
                    <!-- <div v-if="feesPlanList.length > 0"> -->
                    <div class="resrow" 
                      v-for="(plan, index) in feesPlanList"
                      :key="index"
                    >
                     
                      <div  v-if="plan.type == 'Term-Fees'" data-title="Plan Name" class="cell">
                         <a href="javascript:void(0);" class="txtellipsis" :title="plan.planName"  @click.prevent="redirectRoute(`/feesSummary/invoice/details/feePlan/students/listView/${plan._id}`)">{{ plan.planName }} 
                         </a>
                       </div>

					            <div  class="cell"  data-title="Status">{{ plan.type }}</div>

                      <div v-if="plan.type == 'Term-Fees'" class="cell" data-title="Plan Start Date">
                        {{ plan.feesStartDate | dateFormat }}
                      </div>
                      <div v-else class="cell" data-title="Plan End Date">
                        {{ plan.billingDate | dateFormat }}
                      </div>

                      <div v-if="plan.type == 'Term-Fees'" class="cell" data-title="Activation Date">
                        {{ plan.feesEndDate | dateFormat }}
                      </div>
                       <div v-else class="cell" data-title="Fee Amount">
                        {{ plan.billingDate | dateFormat }}
                      </div>


                      <div v-if="plan.type == 'Term-Fees'" class="cell" data-title="Activation Date">
                        {{ plan.createdDate | dateFormat }}
                      </div>
                      <div v-if="plan.type == 'Term-Fees'" class="cell" data-title="Activation Date" style="font-weight: bold;text-align: center;">
                        {{ plan.invoicePlanDetails.length }}
                      </div>
                      <div v-if="plan.type == 'Term-Fees'" class="cell" data-title="Activation Date" style="font-weight: bold;text-align: center;">
                        {{ plan.invoicePlanDetails && plan.invoicePlanDetails.length > 0 ? getPaymentDetails('notPaid',plan.invoicePlanDetails) : '-' }}
                      </div>
                      <div v-if="plan.type == 'Term-Fees'" class="cell" data-title="Activation Date" style="font-weight: bold;text-align: center;">
                        {{ plan.invoicePlanDetails && plan.invoicePlanDetails.length > 0 ? getPaymentDetails('partial',plan.invoicePlanDetails) : '-' }}
                      </div>
                      <div v-if="plan.type == 'Term-Fees'" class="cell" data-title="Activation Date" style="font-weight: bold;text-align: center;">
                        {{ plan.invoicePlanDetails && plan.invoicePlanDetails.length > 0 ? getPaymentDetails('full',plan.invoicePlanDetails) : '-' }}
                      </div>
                      <div v-if="plan.type == 'Term-Fees'" class="cell" data-title="Activation Date" style="font-weight: bold;text-align: center;">
                        {{ plan.invoicePlanDetails && plan.invoicePlanDetails.length > 0 ? getPaymentDetails('Cancelled',plan.invoicePlanDetails) : '-' }}
                      </div>


                      <!-- <div v-if="plan.type == 'Term-Fees'" class="cell" data-title="Action">
                        <div class="dispflex" style="justify-content:stretch">
                          <b-dropdown id="dropdown-1" text="" right  style="margin-right:3px">
                            <b-dropdown-item  @click.prevent="redirectRoute(`/feesManagement/feesSummary/${plan._id}`)">Assigned Fees Summary</b-dropdown-item>
                          </b-dropdown>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
                <div class="text-center mt-4" v-if="feesPlanList.length > 0">
                <div class="pagination" id="pagination">
                  <a  :style="currentPageNumber === 1 ? 'pointer-events: none;' : ''" @click="goToPreviousPage">&laquo;</a>
                  <!-- class="active"  -->
                  <a
                    v-for="(item, index) in lastPageNumber"
                    :key="index"
                    v-on:click="selected = item"
                    v-bind:class="{ active: selected == item }"
                    @click="navToSelectedPage(item)"
                    >{{ item }}</a
                  >
                  <a  :style="currentPageNumber === lastPageNumber ? 'pointer-events: none;' : ''" @click="goToNextPage">&raquo;</a>
                </div>
              </div>
                 <!-- <div class="text-center mt-4" v-if="feesPlanList.length > 0">
                <div class="pagination" id="pagination">
                  <a  :style="currentPageNumber === 1 ? 'pointer-events: none;' : ''" @click="goToPreviousPage">&laquo;</a>
                  <a
                    v-for="(item, index) in lastPageNumber"
                    :key="index"
                    v-on:click="selected = item"
                    v-bind:class="{ active: selected == item }"
                    @click="navToSelectedPage(item)"
                    >{{ item }}</a
                  >
                  <a  :style="currentPageNumber === lastPageNumber ? 'pointer-events: none;' : ''" @click="goToNextPage">&raquo;</a>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <vi-spinner
                v-if="viLoader"
                text="Loading..."
                textColor="vi-brand-color"
                class="flex-fill h-100 vi-fs12"
                style="width: 100%;z-index: 111;top:0;left:0;background-color: rgba(255, 255, 255, 0.5);border-radius: 1rem;"
              />
  </div>
</template>
<script>
import ViService from "@/services/ViService";
import secureUI from "../../utils/secureUI";
import "vue-select/dist/vue-select.css";
import Select from "vue-select";
import ViSpinner from "../Common/ViSpinner";

export default {
  name: "vi-fees-management",
  data() {
    return {
      feePlan: {
        planName: "",
        type: 'Term-Fees',
        academicYear: null,
        feesStartDate: null,
        feesEndDate: null,
        feesDueDate: null,
        classes : [],
      },
      count: {
        feePlanCount : 0,
        feePlanApprovedCount : 0,
        assignFeeTypesCount : 0,
        discountsAndPenaltiesCount : 0,
        assignFeeToStudentsCount : 0
      },
      assignedFeeType: {
        billingFrequency: '',
        isRefundable : false,
        feesPlanId : null,
        feeTermEvery: '',
        feeInvoiceDate: '',
        feeDueDate: '',
        feesTerms : [],
        oneTimeFrequency: []
		  },
      assignedFeesTypeList : [],
      oneTimebillingFrequencyList: [
        'Recuring',
        'Fixed Fee'
      ],
      viLoader: false,
      showPenality: false,
      showDiscount: false,
      isFeePlanNameAvailable: true,
      termList:[],
      termsEvery: [],
      invoiceDates: [
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
        '21',
        '22',
        '23',
        '24',
        '25',
        '26',
        '27',
        '28',
        '29',
        '30',
        '31'
      ],
      invoiceDueDates: [{
        label: '1 day',
        value: '1'
      },
      {
        label: '2 days',
        value: '2'
      },
      {
        label: '3 days',
        value: '3'
      },
      {
        label: '4 days',
        value: '4'
      },
      {
        label: '5 days',
        value: '5'
      },
      {
        label: '6 days',
        value: '6'
      },
      {
        label: '7 days',
        value: '7'
      },
      {
        label: '8 days',
        value: '8'
      },
      {
        label: '9 days',
        value: '9'
      },
      {
        label: '10 days',
        value: '10'
      },
      {
        label: '11 days',
        value: '11'
      },
      {
        label: '12 days',
        value: '12'
      },
      {
        label: '13 days',
        value: '13'
      },
      {
        label: '14 days',
        value: '14'
      },
      {
        label: '15 days',
        value: '15'
      },
      {
        label: '16 days',
        value: '16'
      },
      {
        label: '17 days',
        value: '17'
      },
      {
        label: '18 days',
        value: '18'
      },
      {
        label: '19 days',
        value: '19'
      },
      {
        label: '20 days',
        value: '20'
      },
      {
        label: '21 days',
        value: '21'
      },
      {
        label: '22 days',
        value: '22'
      },
      {
        label: '23 days',
        value: '23'
      },
      {
        label: '24 days',
        value: '24'
      },
      {
        label: '25 days',
        value: '25'
      },
      {
        label: '26 days',
        value: '26'
      },
      {
        label: '27 days',
        value: '27'
      },
      {
        label: '28 days',
        value: '28'
      },
      {
        label: '29 days',
        value: '29'
      },
      {
        label: '30 days',
        value: '30'
      },
      {
        label: '31 days',
        value: '31'
      }
      ],  
      editId: null,
      noOfMonths: 0,
      searchWords: '',
      skip: 0, // set the skip info
      limit: 8, // set the limit info
      currentPageNumber: 1,
      lastPageNumber: 1,
      selected: 1,
      countAll: true,
      totalCount: 0,
      deleteId: null,
      academicYearList: [],
      viewClassIds: [],
      classList: [],
      minPlanDate: null,
      maxPlanDate: null,
      minMonth: false,
      minFromPlanStartDate: null,
      allClassList: [],
      feesPlanList: [],
      allPlanList: [],
      status: 'Draft',
      updateActionId: null,
      updateActionType: '',
      reportingTo: null,
      isFeesTypeAvailable: true,
      selectAcademicYear: null,
      reportingToEditId: null,
      totalCountStudents: 0,
      feetypeAmountLength: 0,
      role: {},
      preFeePlanName: '',
      assignedTermsIndex: null,
      assignFeeEditId: null,
      multiselectForTerm: [],
      internalUserList: [],
      feesTypeList: [],
      billingFrequencyList: []
    };
  },
  created() {
    this.getAcademicYearList();
    this.getFeesPlanList('new');
  },
  computed: {
      styles(){
       var brwHeight = window.innerHeight;
       return{
         height: (brwHeight - 90) + 'px'
       };
      }
  },
  watch: {
          searchWords: function (currentVal, oldVal) {
               let hasCurrentParam = currentVal || '';
               let hasOldParam = oldVal || '';

              if (hasCurrentParam != hasOldParam) {
                   this.skip = 0
                   this.currentPageNumber = 1,
                   this.lastPageNumber = 1,
                   this.selected = 1,
                   this.totalCount = 0,
				           this.countAll = true,
                   this.getFeesPlanList('old');
              }
          }
  },
  methods: {
    previousRoute(){
     let data = localStorage.getItem('previousTab');
      let data2 = localStorage.getItem('activeTab');
     

        if(data2 == '/feesManagement/home'){
          localStorage.setItem("activeTab",'/feesManagement/home');
          localStorage.setItem("previousTab",'/feesManagement/home');
        }else{
           localStorage.setItem("activeTab",data);
          localStorage.setItem("previousTab",data);
        }
     this.$router.go(-1)
    },
    async navToSelectedPage(selectedPageNo) {

      this.skip = (this.limit * (selectedPageNo - 1));

      this.countAll = false

      this.getFeesPlanList('old');
    },
   
    async goToNextPage() {
      this.countAll = false
      this.currentPageNumber = this.currentPageNumber + 1;
      this.selected = this.currentPageNumber;
      this.skip = (this.limit * (this.currentPageNumber - 1));

      this.getFeesPlanList('old');
    },
    async goToPreviousPage() {
       this.countAll = false
      this.currentPageNumber = this.currentPageNumber - 1;
      this.selected = this.currentPageNumber;
      this.skip = (this.limit * (this.currentPageNumber - 1));
     
      this.getFeesPlanList('old');
    },
    redirectRoute(vipath) {
      if (this.$route.path !== vipath) this.$router.push({ path: vipath });
    },
     getSelectAcademicYear(){

          if(this.selectAcademicYear){
            this.getFeesPlanList('old');
          }else{
            this.selectAcademicYear = null
		        this.getFeesPlanList('old');
          }

          this.skip = 0
          this.currentPageNumber = 1,
          this.lastPageNumber = 1,
          this.selected = 1,
          this.totalCount = 0,
          this.countAll = true
    },
   
    async getAcademicYearList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXGet(
          "/academicYear/getAcademicYearListBranch",
          userData.token
        );
        if (response.isSuccess) {
          this.academicYearList = secureUI.secureGet(response.data);
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    getPaymentDetails(type,data){

     if(type == 'notPaid'){
       const notPaidList = data.filter( x => x.status == 'Not Paid')
       if(notPaidList.length > 0){
         return notPaidList.length
       }else{
         return '-'
       }
     }

     if(type == 'partial'){
        const notPaidList = data.filter( x => x.status == 'Partial Paid')

        if(notPaidList.length > 0){
          return notPaidList.length
        }else{
          return '-'
        }
     }

     if(type == 'full'){
        const notPaidList = data.filter( x => x.status == 'Full Paid')

       if(notPaidList.length > 0){
         return notPaidList.length
       }else{
         return '-'
       }
     }

      if(type == 'Cancelled'){
        const notPaidList = data.filter( x => x.status == 'Cancelled')

       if(notPaidList.length > 0){
         return notPaidList.length
       }else{
         return '-'
       }
     }
    
    },
    async getFeesPlanList(type) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        if(type == 'new') {
         this.viLoader = true;
       }
        const response = await ViService.viXGet(`/feesManagement/getInvoiceFeesPlanListTeacherView?skip=${this.skip}&limit=${this.limit}&search=${this.searchWords}&isCount=${this.countAll}&academicYear=${this.selectAcademicYear}`,userData.token);
 
        if (response.isSuccess) {

           const resultData = secureUI.secureGet(response.data);
           
           this.feesPlanList = resultData.feesPlanList

          if(this.countAll){
            this.totalCount = resultData.totalCount;
            this.lastPageNumber = Math.ceil(this.totalCount/this.limit);
          }
        
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
        this.viLoader = false;
      }
    }
  },
  components: {
    ViSpinner,
  }
};
</script>